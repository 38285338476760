@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --text-primary: #396aff;
    --text-green: #0bb07b;
    --text-yellow: #ff8f00;
    --text-red: #ff5d5d;
    --text-main-dark: #343c6a;
    --text-secondary-dark: #96a9c8;
    --text-disabled-dark: #f0f3f7;
    --text-main-light: #ffffff;
    --text-secondary-light: #ccced9;
    --text-disabled-light: #999db4;
    --hover-primary: #3760e1;
    --hover-secondary: #ebf0ff;
    --hover-red: #eb5353;
    --active-primary: #2a4fbf;
    --active-secondary: #d2dcfa;
    --active-red: #bf4545;
    --disable-primary: #f0f3f7;
    --disable-secondary: #fbfcfd;
    --shadow0-0: #1285ff;
    --shadow0-1: #20d1ff;
    --shadow1-0: #ff5980;
    --shadow1-1: #ffa882;
    --shadow2-0: #4639ff;
    --shadow2-1: #5698ff;
    --shadow3-0: #d438ff;
    --shadow3-1: #8c58ff;
    --shadow5-0: #57d968;
    --shadow5-1: #00d590;
    --font-small: 10px;
    --font-caption: 12px;
    --font-caption-sm: 14px;
    --font-body: 14px;
    --font-subheader: 16px;
    --font-title: 20px;
    --font-headline: 24px;
    --font-headline-sm: 20px;
    --font-display: 36px;
    --font-jumbo: 48px;
    --font-mega: 72px;

    /* Toastify */

    --toastify-color-light: #fff;
    --toastify-color-dark: #121212;
    --toastify-color-info: #3498db;
    --toastify-color-success: #cff8eb;
    --toastify-color-warning: #ffecc7;
    --toastify-color-error: #e74c3c;
    --toastify-color-transparent: rgba(255, 255, 255, 0.7);

    --toastify-icon-color-info: var(--toastify-color-info);
    --toastify-icon-color-success: var(--toastify-color-success);
    --toastify-icon-color-warning: var(--toastify-color-warning);
    --toastify-icon-color-error: var(--toastify-color-error);

    --toastify-toast-width: 320px;
    --toastify-toast-background: #fff;
    --toastify-toast-min-height: 64px;
    --toastify-toast-max-height: 800px;
    --toastify-font-family: sans-serif;
    --toastify-z-index: 9999;

    --toastify-text-color-light: #757575;
    --toastify-text-color-dark: #fff;

    /* Used only for colored theme */
    --toastify-text-color-info: #fff;
    --toastify-text-color-success: #0bb07b;
    --toastify-text-color-warning: #ff8f00;
    --toastify-text-color-error: #fff;

    --toastify-spinner-color: #616161;
    --toastify-spinner-color-empty-area: #e0e0e0;

    /* Used when no type is provided
   toast("**hello**") */
    --toastify-color-progress-light: linear-gradient(
      to right,
      #4cd964,
      #5ac8fa,
      #007aff,
      #34aadc,
      #5856d6,
      #ff2d55
    );
    /* Used when no type is provided */
    --toastify-color-progress-dark: #bb86fc;
    --toastify-color-progress-info: var(--toastify-color-info);
    --toastify-color-progress-success: var(--toastify-color-success);
    --toastify-color-progress-warning: var(--toastify-color-warning);
    --toastify-color-progress-error: var(--toastify-color-error);
  }

  html {
    scroll-behavior: smooth;
  }

  html,
  body,
  #root {
    min-height: 100%;
    margin: 0;
  }
  * {
    font-family: "Open Sans", sans-serif;
  }

  p,
  b,
  h1,
  h2,
  h3,
  option {
    color: #343c6a;
  }

  .header-description,
  .header-description p {
    color: #fff !important;
  }

  .navbar-bottom-menu:hover ~ #navbar {
    display: block;
  }
  
  .input-control label,
  .input-control .special-label {
    display: block;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
    transition: all 0.3s ease-in-out;
    opacity: 0;
    pointer-events: none;
    font-size: 14px;
    position: absolute;
    @apply text-gray-300;
  }
  .input-control label.label-location {
    @apply left-8;
  }
  .input-control:focus {
    border: 1px solid red;
  }
  .input-control .form-control {
    transition: all 0.3s ease-in-out;
    @apply w-full px-3 py-2 h-[48px] text-xs;
  }

  .input-control .flag-dropdown {
    @apply right-0 border rounded-r-md;
  }

  .input-control .flag-dropdown .selected-flag {
    @apply px-3 w-[56px] rounded-r-md;
  }
  .input-control .selected-flag:hover,
  .input-control .selected-flag:focus,
  .input-control .selected-flag.open {
    @apply bg-white;
  }

  .input-control input::placeholder {
    color: transparent;
  }
  .input-control input:focus {
    padding-bottom: 0px;
  }

  .input-control input + label.error,
  .input-control input + .special-label.error {
    color: var(--text-red);
  }
  .input-control input:focus + label,
  .input-control:focus-within .special-label {
    color: var(--text-primary);
    transform: translateY(-18px);
    opacity: 1;
    font-size: 10px;
    font-weight: bold;
  }
  .input-control:not(:focus-within) .special-label {
    transform: translateY(-18px);
    opacity: 1;
    font-size: 10px;
  }
  .input-control input:placeholder-shown + label,
  .input-control input:placeholder-shown + .special-label {
    opacity: 1;
  }
  .input-control input:not(:placeholder-shown) + label,
  .input-control input:not(:placeholder-shown) + .special-label {
    color: var(--text-primary);
    transform: translateY(-18px);
    font-size: 10px;
    opacity: 1;
    font-weight: bold;
  }
  .input-control input:not(:placeholder-shown) + label.error,
  .input-control input:not(:placeholder-shown) + .special-label.error {
    color: var(--text-red);
  }
  .input-control input:not(:placeholder-shown) {
    padding-bottom: 0px;
  }

  .single-line-wrap {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .double-line-wrap {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .hide-scrollbar::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    display: none;
  }
  .hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
  }
  .toggle-checkbox:checked {
    @apply: right-0 border-green-400;
    right: 0;
    border-color: #68d391;
  }
  .toggle-checkbox:checked + .toggle-label {
    @apply: bg-green-400;
    background-color: #68d391;
  }

  input[type="radio"]:checked + .radio::after {
    @apply scale-100;
  }
  input[type="radio"]:checked + .radio {
    @apply border-primary-1000;
  }

  .sidebar-item {
    @apply h-0 opacity-0 overflow-hidden;
  }
  .sidebar-item.active {
    @apply h-auto opacity-100;
  }
  .tickbox:checked[type="checkbox"] {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3 6-6'/%3E%3C/svg%3E");
  }

  .accordion-menu > .accordion-item {
    @apply h-0 space-y-0 mt-0 px-4;
  }

  .accordion-menu > .accordion-title > i {
    @apply text-gray-300;
  }

  .accordion-menu.active > .accordion-item {
    @apply space-y-3 mt-4 h-auto pb-3;
  }
  .accordion-menu.active > .accordion-title > i {
    @apply -rotate-180 text-primary-1000;
  }

  .loading.wave-animation span {
    display: inline-block;
    animation: wave-text 1s ease-in-out infinite;
  }

  .loading.wave-animation span:nth-of-type(1) {
    animation-delay: 0s;
  }
  .loading.wave-animation span:nth-of-type(2) {
    animation-delay: 0.1s;
  }
  .loading.wave-animation span:nth-of-type(3) {
    animation-delay: 0.2s;
  }
  .loading.wave-animation span:nth-of-type(4) {
    animation-delay: 0.3s;
  }
  .loading.wave-animation span:nth-of-type(5) {
    animation-delay: 0.4s;
  }
  .loading.wave-animation span:nth-of-type(6) {
    animation-delay: 0.5s;
  }
  .loading.wave-animation span:nth-of-type(7) {
    animation-delay: 0.6s;
  }
  .loading.wave-animation span:nth-of-type(8) {
    animation-delay: 0.7s;
  }
  .loading.wave-animation span:nth-of-type(9) {
    animation-delay: 0.8s;
  }

  @keyframes wave-text {
    00% {
      transform: translateY(0em);
    }
    60% {
      transform: translateY(-0.5em);
    }
    100% {
      transform: translateY(0em);
    }
  }
  .tooltip-menu {
    @apply hidden;
  }
  .tooltip-container.active .tooltip-menu {
    @apply block;
  }
  table {
    white-space: nowrap;
    margin: 0;
    padding: 0;
    border: none;
    border-collapse: separate;
    border-spacing: 0;
    table-layout: fixed;
  }
  /* Freeze first column table */
  /* table thead th {
    position: sticky;
    top: 0;
    z-index: 1;
    background: white;
  }

  table tbody th {
    position: relative;
  }
  table thead th:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
  }
  table tbody th {
    position: sticky;
    left: 0;
    background: white;
    z-index: 1;
  }
  th:first-child {
    @apply sticky left-0;
  } */
}

@screen lg {
  .tooltip-container.active .tooltip-menu {
    @apply hidden;
  }
  .tooltip-container.active .tooltip-menu.show {
    @apply block;
  }
}
